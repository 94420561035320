import React from 'react'
import PostCard from '../components/PostCard'
import { ArrowRight, Filter, Hash } from 'react-feather'
import _ from 'lodash'

import './PostSection.css'

export default class PostSection extends React.Component {
  constructor(props){
    super(props)

    this.state = { 
      limit: this.props.limit,
      renderedTags: [],
      visiblePosts: []
    }
  }
  static defaultProps = {
    posts: [],
    title: '',
    limit: 12,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 12
  }

  increaseLimit = () => {
    this.setState(prevState => ({limit: prevState.limit + this.props.perPageLimit}))
  }
  prettifyTitle(title){
    let converted = '';
    if(_.includes(title, 'toolsTechartTopics')){
      converted = 'Tools & Techart Topics'
    }else if (_.includes(title, 'Work')){
      converted = title.replace('Work', '')
    }else{
      converted = 'All'
    }

    return converted
  }
  filterTags = (posts) => {
    let visibleTags = [];
    let currentTags = [];
    _.forEach(posts, (post, idx) =>{
      if(post.tags.length <= 1){
        if(post.tags[0] !== "") currentTags.push(post.tags[0])
      }else{
        _.forEach(post.tags, (pt) =>{if(!_.includes(currentTags, pt) && pt !== "") currentTags.push(pt)})
      }
    })
    visibleTags = _.map(currentTags, (ct) =>{return {'value': ct, 'active': false}})

    return visibleTags
  }

  handleFilterClick = (idx, value, e) =>{
    let current = this.state.renderedTags  
    let activePosts = [];
    current[idx]['active'] = !this.state.renderedTags[idx]['active']
    this.setState({renderedTags: current})
    let activeTags = _.filter(current, 'active');
    _.forEach(_.filter(current, 'active'), (tag) => {
      _.forEach(this.byTagCategory(this.props.posts, tag.value), (post) => {
        activePosts.push(post)
      })
    })
    activeTags.length > 0 ? this.setState({visiblePosts: _.uniq(activePosts)}): this.setState({visiblePosts: this.props.posts})
  }
  byTagCategory = (posts, filter) => {
    return _.filter(posts, post => _.includes(post.tags, filter))
  }

  getPostsByLimit = ( limitCount = false ) => {
    let limited = [];
    if (limitCount) {
      this.increaseLimit()
    }
    limited = this.props.posts.slice(0, this.state.limit || this.props.posts.length)

    this.setState({visiblePosts: limited})
  }

  componentDidMount(){
    let rtags = this.filterTags(this.props.posts);
    this.getPostsByLimit()

    this.setState({renderedTags: rtags})
    
  }

  render() {
    const { posts, title, showLoadMore, loadMoreTitle } = this.props,
      { renderedTags, visiblePosts } = this.state,
      visibleTags = this.filterTags(posts);

    return (
      <div className="PostSection">
        <div className="title__container">
          <h2 className="PostSection--Title">WORK</h2>
          <p>{this.prettifyTitle(title)}</p>
        </div>

        {renderedTags.length > 0 && (
          <div className="filterContainer">
            <div className="filterLabelContainer">
              <div className="filterLabelItem"><Filter size='15'/></div>
              <div className="filterLabelItem"><span>Filter By:</span></div>
            </div>
            <div className="tagsComponents">
              {renderedTags.map((tag, idx) => (
                <div  className={tag.active ? "tagsSingle active" : "tagsSingle"} key={tag.value + idx} onClick={(e) => this.handleFilterClick(idx, tag.value, e)} role="button">
                  <Hash size='25' className="tagSVG"/>
                  <span>{tag.value}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {!!visiblePosts.length && (
          <div className="PostSection--Grid">
            {visiblePosts.map((post, index) => (
              <PostCard key={post.title + index} {...post} />
            ))}
          </div>
        )}
        {showLoadMore && visiblePosts.length < posts.length && (
          <div className="taCenter">
            <button className="button circleButton left" onClick={() => this.getPostsByLimit(true)}>              
              <span className="txt">{loadMoreTitle}</span>
              <span className="circle"></span>
              <ArrowRight size='15'/>
            </button>
          </div>
        )}
      </div>
    )
  }
}
