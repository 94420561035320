import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'
import _ from 'lodash'

import PostSection from '../components/PostSection'
import Layout from '../components/Layout'

export const byDate = posts => {
  const now = Date.now()
  return posts.filter(post => Date.parse(post.date) <= now)
}

export const byCategory = (posts, title) => {
  return posts.filter(post => _.camelCase(post.category.replace(' ', ''))=== title)
}

export const WorkIndexTemplate = ({
  title,
  subtitle,
  featuredImage,
  thumbNail,
  works = [],
  workCategories = [],
  enableSearch = true,
  contentType,
  lang,
  tags
}) => (
  <Location>
    {({ location }) => {
      let filter = location.pathname.replace(`/${lang}/works/`, ""),
      filteredPosts = [];
      _.isEmpty(filter) ? filteredPosts = works : filteredPosts = byCategory(works, filter) ;

      let queryObj = location.search.replace('?', '');
      queryObj = qs.parse(queryObj);
      if(enableSearch && queryObj.s){
        let searchTerm = queryObj.s.toLowerCase();
        works.frontmatter.title.toLowerCase().includes(searchTerm)
      }

      return (
        <main className="Blog">
          {!!works.length && (
            <section className="section">
                <PostSection posts={filteredPosts} title={filter} tags={tags}/>  
            </section>
          )}
        </main>
      )
    }}
  </Location>
)

// Export Default BlogIndex for front-end
const WorkIndex = ({ 
  pageContext: {locale},
  location: {pathname},
  data: { page, works, workCategories, tagsGroup } }) => (
  <Layout title={page.frontmatter.pagetitle || false} path={pathname}>
    <WorkIndexTemplate
      lang={locale}
      {...page}
      {...page.fields}
      {...page.frontmatter}
      works={works.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      tags={tagsGroup.group}
      workCategories={workCategories.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default WorkIndex

export const pageQuery = graphql`
  query WorkIndex($locale: String!, $id: String!) {
    page: markdownRemark(frontmatter: {locale: {eq: $locale}}, id: {eq: $id}) {
      fields {
        contentType
      }
      frontmatter {
        pagetitle
        template
        subtitle
        featuredImage
      }
    }

    works: allMarkdownRemark(
      filter: {fields: {contentType: {eq: "works"}, locale: {eq: $locale}}}
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date
            category
            featuredImage
            thumbNail
            tags
          }
        }
      }
    }
    tagsGroup: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    workCategories: allMarkdownRemark(
      filter: {fields: {contentType: {eq: "workCategories"}, locale: {eq: $locale}}}
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
