import React from 'react'
import { Link } from 'gatsby'

import './PostCard.css'

const PostCard = ({
  featuredImage,
  thumbNail,
  title,
  subtitle,
  slug,
  className = '',
  ...props
}) => (
  <Link to={slug} className={`PostCard ${className}`}>
    {thumbNail && (<img src={thumbNail} alt={title} className="PostCard--Image"/>)}
    <div className="PostCard--Content">
      {title && <h3 className="PostCard--Title">{title}</h3>}
      <div className="PostCard--Subtitle">
        {subtitle}
      </div>
    </div>
  </Link>
)

export default PostCard
